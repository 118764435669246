import React from "react";
import "./App.css";
import photo from "./res/photo.jpg";

function App() {
  return (
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-sm-4">
          <img className="img-fluid" src={photo} />
        </div>
        <div className="col-sm-4 d-flex justify-content-around flex-column">
          <h1 className="display-5">Joshua Pearlman</h1>
          <h1 className="display-6">Undergraduate Student</h1>
          <h1 className="display-6">Studying Computer Science at MTU</h1>
        </div>
      </div>
      <div className="row mt-5">
        <p className="lead">
          My name is Joshua Pearlman, and I am a senior undergraduate student at
          Michigan Technological University, majoring in computer science. After
          graduation, I plan to continue my academic journey at Michigan Tech by
          pursuing a master’s degree in computer science.
        </p>
      </div>

      <div className="row mt-5">
        <h1 className="display-6">Education</h1>
      </div>
      <div className="row mt-2">
        <span className="col-sm-5">
          <dt className="col-sm">Michigan Technological University</dt>
          <dd className="col-sm"> BS in Computer Science</dd>
        </span>
        <span className="col">August 2022 - April 2025 (Expected)</span>
      </div>
      <div className="row mt-2">
        <span className="col-sm-5">
          <dt className="col-sm">Michigan Technological University</dt>
          <dd className="col-sm"> MS in Computer Science</dd>
        </span>
        <span className="col">August 2025 - April 2026 (Expected)</span>
      </div>


      <div className="row mt-5">
        <h1 className="display-6">Work Experience</h1>
      </div>
      <div className="row mt-2">
        <span className="col-sm-5">
          <dt className="col-sm">Undergraduate Researcher</dt>
          <dd className="col-sm">Michigan Technological University</dd>
        </span>
        <span className="col">Janurary 2024 - Present</span>
      </div>
      <div className="row mt-2">
        <span className="col-sm-5">
          <dt className="col-sm">Web App Developer</dt>
          <dd className="col-sm">Michigan Technological University</dd>
        </span>
        <span className="col">April 2024 - Present</span>
      </div>
      <div className="row mt-2">
        <span className="col-sm-5">
          <dt className="col-sm">Student Academic Mentor</dt>
          <dd className="col-sm">Michigan Technological University</dd>
        </span>
        <span className="col">August 2024 - December 2024</span>
      </div>
    </div>
  );
}

export default App;
